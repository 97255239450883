import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import Plaatjie from '@ubo/plaatjie'
import { motion } from 'framer-motion'

// Elements
import ParseContent from 'components/shared/ParseContent'
import NavLink from 'components/shared/NavLink'

// Svg
import LocationIcon from 'img/location.inline.svg'
import ArrowCircle from 'img/arrow-circle-secondary.inline.svg'

// Images
import Arrow from 'img/arrow.inline.svg'
import Phone from 'img/phone.inline.svg'
import Email from 'img/email.inline.svg'
import Fax from 'img/fax.inline.svg'

// Interface
import { LocationsProps } from 'components/flex/Locations/LocationsShell'

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-size: ${({ theme }) => theme.font.size.ultra};
    line-height: ${({ theme }) => theme.font.size.ultra};
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }
`

const Location = styled.div`
  & svg path {
    stroke: ${({ theme }) => theme.color.secondary};
  }

  & span {
    color: ${({ theme }) => theme.color.secondary};
  }
`

const LocationContent = styled(ParseContent)`
  color: ${({ theme }) => theme.color.primary};

  & p {
    margin-bottom: 0;
  }
`

const Pointer = styled.div<{ x?: number; y?: number; open?: boolean }>`
  ${(props) =>
    props.x &&
    props.y &&
    css`
      top: ${props.y}%;
      left: ${props.x}%;
    `}

  & svg {
    width: 100%;
    & path {
      fill: #808080;
    }

    @media (max-width: 991.98px) {
      height: 36px;
    }

    @media (min-width: 992px) {
      height: 69px;
    }
  }

  ${(props) =>
    props.open &&
    css`
      & svg {
        position: relative;
        z-index: 2;
        pointer-events: none;
        & path {
          fill: #105e94 !important;
        }
      }
    `}
`

const Section = styled.section`
  & .location-info svg {
    width: 20px;
    margin-right: 8px;
    path {
      fill: #14753d;
    }
  }

  a:hover {
    text-decoration: underline;
  }
`

const Links = styled.div`
  & a {
    @media (min-width: 992px) {
      font-size: ${({ theme }) => theme.font.size.medium};
    }

    @media (max-width: 991px) {
      font-size: ${({ theme }) => theme.font.size.small};
    }
  }
`

const TitleLocation = styled.button`
  width: 100%;
  padding: 0;
  font-weight: ${({ theme }) => theme.font.weight.bold};

  & svg path {
    stroke: ${({ theme }) => theme.color.secondary};
  }
`

const ContentLocation = styled(motion.div)`
  overflow: hidden;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey};
`

const Locations: React.FC<LocationsProps> = ({ fields }) => {
  const [open, setOpen] = useState(0)

  return (
    <Section className="mb-5 pb-lg-5 position-relative">
      <div className="container py-5">
        <div className="row">
          <div className="col-lg-8">
            <div className="position-relative">
              <Plaatjie
                image={fields.image}
                alt=""
                className="pointer-events-none"
              />
              {fields.locations?.map((location, index) => (
                <Pointer
                  className="position-absolute"
                  x={location?.x || 0}
                  y={location?.y || 0}
                  key={index}
                  open={index === open}
                >
                  <svg
                    width="23"
                    height="23"
                    viewBox="0 0 23 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.3051 0.70752C7.17236 0.70752 3.81006 4.06982 3.81006 8.20254C3.81006 13.3969 11.3125 22.27 11.3125 22.27C11.3125 22.27 18.8001 13.1415 18.8001 8.20254C18.8001 4.06982 15.4379 0.70752 11.3051 0.70752ZM13.5665 10.3971C12.9429 11.0205 12.1241 11.3323 11.3051 11.3323C10.4862 11.3323 9.6671 11.0205 9.04381 10.3971C7.79683 9.15024 7.79683 7.12139 9.04381 5.87441C9.64762 5.27034 10.4508 4.93763 11.3051 4.93763C12.1593 4.93763 12.9624 5.27047 13.5665 5.87441C14.8135 7.12139 14.8135 9.15024 13.5665 10.3971Z"
                      fill="white"
                    />
                  </svg>
                </Pointer>
              ))}
            </div>
          </div>
          <div className="col-lg-4">
            <Content content={fields.description} className="mb-5" />
            {fields.locations?.map((location, index) => {
              const isOpen = index === open

              return (
                <React.Fragment key={index}>
                  <TitleLocation
                    type="button"
                    onClick={() => {
                      setOpen(index)
                    }}
                    className="d-flex align-items-center justify-content-between py-3"
                  >
                    <div>
                      <LocationIcon className="me-2" />
                      {location?.title}
                    </div>
                    <motion.div
                      animate={isOpen ? { rotate: 180 } : { rotate: 0 }}
                      transition={{ duration: 0.2 }}
                    >
                      <ArrowCircle />
                    </motion.div>
                  </TitleLocation>
                  <ContentLocation
                    animate={isOpen ? 'open' : 'closed'}
                    variants={{
                      open: {
                        height: 'auto',
                      },
                      closed: {
                        height: 0,
                      },
                    }}
                    transition={{
                      duration: 0.2,
                    }}
                  >
                    <Location className="d-flex flex-sm-row flex-column justify-content-sm-between align-items-sm-center mb-4">
                      <div className="d-flex align-items-start">
                        <LocationContent
                          className="ps-2"
                          content={location?.location}
                        />
                      </div>

                      {location?.link?.title && (
                        <NavLink
                          to={`${location?.link?.url}#${index}` || '#'}
                          className="d-flex align-items-center"
                        >
                          <span className="font-weight-bold">
                            {location?.link?.title}
                          </span>
                          <Arrow className="ms-3" />
                        </NavLink>
                      )}
                    </Location>
                    <Links className="mb-5">
                      {(location?.phone ||
                        location?.email ||
                        location?.fax) && (
                        <div className="location-info">
                          {location?.phone && (
                            <a
                              className="d-flex align-items-center mb-2"
                              href={location.phone?.url}
                            >
                              <Phone />
                              <span>{location?.phone?.title}</span>
                            </a>
                          )}
                          {location?.fax && (
                            <a
                              className="d-flex align-items-center mb-2"
                              href={location.fax?.url}
                            >
                              <Fax />
                              <span>{location?.fax?.title}</span>
                            </a>
                          )}
                          <a
                            className="d-flex align-items-center mb-2"
                            href={location.email?.url}
                          >
                            <Email />
                            <span>{location?.email?.title}</span>
                          </a>
                          {location.extraemail?.url && (
                            <a
                              className="d-flex align-items-center mb-2"
                              href={location.extraemail?.url}
                            >
                              <Email />
                              <span>{location?.extraemail?.title}</span>
                            </a>
                          )}
                          {/* Extra email */}
                          {location.extraExtraEmail?.url && (
                            <a
                              className="d-flex align-items-center mb-2"
                              href={location.extraExtraEmail?.url}
                            >
                              <Email />
                              <span>{location?.extraExtraEmail?.title}</span>
                            </a>
                          )}
                        </div>
                      )}
                    </Links>
                  </ContentLocation>
                </React.Fragment>
              )
            })}
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Locations
